import React, { Component, Fragment } from 'react'
import { Navbar, Container, Nav } from 'react-bootstrap'
import classNames from 'classnames'

import Logo from '../../assets/img/Team_Spook.png'

class DefaultHeader extends Component {
  constructor (props) {
    super(props)
    let pathname = props.history.location.pathname
    this.state = {
      collapsed: false,
      width: 0,
      height: 0,
      open: false,
      openDropdown: false,
      activeTab: pathname.includes('/admin/user') ? '1' :
                 pathname.includes('/admin/ticket') ? '2' :
                 pathname.includes('/admin/payment') ? '3' : '1'
    }
    this.toggle = this.toggle.bind(this)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.logout = this.logout.bind(this)
    this.toggleTab = this.toggleTab.bind(this)
  }

  toggle () {
    this.setState({
      openDropdown: !this.state.openDropdown
    })
  }

  toggleTab (tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }

  logout () {
    localStorage.removeItem('gcr-token')
    this.props.isAdminFunc(false)
    this.props.history.push('/login')
  }

  closeModal () {
    this.setState({ open: !this.state.open })
  }

  componentDidMount () {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions () {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  toggleNavbar () {
    this.setState({ collapsed: !this.state.collapsed })
  }

  render () {
    const windowWidth = window.innerWidth
    const thresholdWidth = 768
    return (
      <Fragment>
        <Navbar expand={windowWidth > thresholdWidth}>
          <Container>
            <Navbar.Brand className='gcr-brand'>
              <img src={Logo} height='50px' alt='Logo' />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls='gcr-navbar' />
            <Navbar.Collapse id='gcr-navbar'>
              {this.props.isAdmin() ? (
                <Nav className='ml-auto'>
                  <Nav.Link
                    href='/admin/users'
                    className={classNames({ active: this.state.activeTab === '1' })}
                    onClick={() => this.toggleTab('1')}
                  >USERS</Nav.Link>
                  <Nav.Link
                    href='/admin/tickets'
                    className={classNames({ active: this.state.activeTab === '2' })}
                    onClick={() => this.toggleTab('2')}
                  >TICKETS</Nav.Link>
                  <Nav.Link
                    href='/admin/payments'
                    className={classNames({ active: this.state.activeTab === '3' })}
                    onClick={() => this.toggleTab('3')}
                  >PAYMENTS</Nav.Link>
                </Nav>
              ) : null}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Fragment>
    )
  }
}

export default DefaultHeader
