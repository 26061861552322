import React from "react";
import Loadable from "react-loadable";
import DefaultLayout from "./containers/DefaultLayout";

function Loading() {
  return <div className="h-100vh" />;
}

const Admin = Loadable({
  loader: () => import("./views/Admin"),
  loading: Loading,
});

const AdminPayments = Loadable({
  loader: () => import("./views/AdminPayments"),
  loading: Loading,
});

const AdminUserManagement = Loadable({
  loader: () => import("./views/AdminUserManagement"),
  loading: Loading,
});

const AdminReport = Loadable({
  loader: () => import("./views/AdminReport"),
  loading: Loading,
});

const AdminReports = Loadable({
  loader: () => import("./views/AdminReports"),
  loading: Loading,
});

const AdminOrders = Loadable({
  loader: () => import("./views/AdminOrders"),
  loading: Loading,
});

const Dashboard = Loadable({
  loader: () => import("./views/Dashboard"),
  loading: Loading,
});

const Home = Loadable({
  loader: () => import("./views/Home"),
  loading: Loading,
});

const Order = Loadable({
  loader: () => import("./views/Order"),
  loading: Loading,
});

const Pay = Loadable({
  loader: () => import("./views/Pay"),
  loading: Loading,
});

const OrderPayed = Loadable({
  loader: () => import("./views/OrderPayed"),
  loading: Loading,
});

const routes = [
  { path: "/", name: "Home", component: DefaultLayout, exact: true },
  //Real Pages
  { path: "/admin/dashboard", name: "Admin", component: Admin },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  { path: "/admin/payments", name: "Payments", component: AdminPayments },
  {
    path: "/admin/users",
    name: "AdminUserManagement",
    component: AdminUserManagement,
  },
  { path: "/admin/ticket/:id", name: "Ticket", component: AdminReport },
  { path: "/admin/tickets", name: "Tickets", component: AdminReports },
  { path: "/admin/orders", name: "AdminOrders", component: AdminOrders },
  { path: "/order", name: "Order", component: Order },
  { path: "/pay/:id", name: "Pay", component: Pay },
  { path: "/order-payed/:id", name: "OrderPayed", component: OrderPayed },
  { path: "/home", name: "Home", component: Home },
];

export default routes;
