import React, { Component } from "react";
import { compose, graphql } from "react-apollo";
import SweetAlert from "react-bootstrap-sweetalert";
import { withRouter } from "react-router-dom";
import { Button, Card, CardBody, Container, Input } from "reactstrap";
import LoginMutation from "../../../mutations/Login.js";
import "./Login.css";

class Login extends Component {
  state = {
    email: "",
    password: "",
    alert: false,
  };

  render() {
    return (
      <Container className="login-view">
        {this.state.alert}
        <h1 className="title">Log In</h1>
        <Card className="login-card">
          <CardBody className="login-card-body">
            <Input
              type="email"
              placeholder="Email"
              onChange={(e) => this.setState({ email: e.target.value })}
            ></Input>
            <Input
              type="password"
              placeholder="Password"
              onChange={(e) => this.setState({ password: e.target.value })}
            ></Input>
            <Button color="primary" onClick={() => this._login()}>
              Login
            </Button>
          </CardBody>
        </Card>
      </Container>
    );
  }

  _login = async () => {
    let { loginMutation } = this.props;
    let variables = {
      email: this.state.email,
      password: this.state.password,
    };

    if (!variables.email || !variables.password)
      return this.setState({
        alert: (
          <SweetAlert
            warning
            title="Uh Oh!"
            onConfirm={() => {
              this.setState({ alert: null });
            }}
            closeOnClickOutside={true}
          >
            Please fill all fields.
          </SweetAlert>
        ),
      });

    try {
      let mutationResponse = await loginMutation({ variables });
      if (mutationResponse.data) {
        const role = mutationResponse.data.login.user.role;

        localStorage.setItem(
          "med-cert-token",
          mutationResponse.data.login.token
        );
        localStorage.setItem(
          "med-cert-role",
          mutationResponse.data.login.user.role
        );
        localStorage.setItem(
          "med-cert-id",
          mutationResponse.data.login.user.id
        );

        if (
          role === "SYSTEMADMIN" ||
          role === "ADMINISTRATOR" ||
          role === "USER"
        ) {
          this.props.history.replace("/admin/orders");
        } else {
          this.props.history.replace("/order");
        }
      } else {
        console.log("Something went wrong with login!");
      }
    } catch (e) {
      this.setState({
        alert: (
          <SweetAlert
            warning
            title="Uh Oh!"
            onConfirm={() => {
              this.setState({ alert: null });
            }}
            closeOnClickOutside={true}
          >
            Something went wrong with login. Please try again! If this error
            persists, please contact Borilabs, LLC.
          </SweetAlert>
        ),
      });
    }
  };
}

export default compose(graphql(LoginMutation, { name: "loginMutation" }))(
  withRouter(Login)
);
