import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Container } from 'reactstrap'

import { AppHeader } from '@coreui/react'
import routes from '../../routes'
import DefaultHeader from './DefaultHeader'

class DefaultLayout extends Component {
  constructor (props) {
    super(props)
    this.events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress'
    ]
    this.state = {
      isAdminPage: false
    }
    this.logout = this.logout.bind(this)
    this.resetTimeout = this.resetTimeout.bind(this)
    this.isAdminPage = this.isAdminPage.bind(this)

    for (let i; i < this.events.length; i += 1) {
      window.addEventListener(this.events[i], this.resetTimeout)
    }

    this.setTimeout()
  }

  isAdminPage (value) {
    this.setState({ isAdminPage: value })
  }

  isAdmin () {
    if (localStorage.getItem('med-certs-role') === 'SYSTEMADMIN') {
      return true
    } else {
      return false
    }
  }

  setTimeout () {
    this.logoutTimeout = setTimeout(this.logout, 1800000)
  }

  clearTimeout () {
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout)
  }

  resetTimeout () {
    this.clearTimeout()
    this.setTimeout()
  }

  logout () {
    localStorage.removeItem('gcr-token')
    window.location.reload()
    this.destroy() // Cleanup
  }

  destroy () {
    this.clearTimeout()

    for (let i; i < this.events.length; i += 1) {
      window.removeEventListener(this.events[i], this.resetTimeout)
    }
  }

  render () {
    const { history } = this.props
    if (this.state.isAdminPage && !this.isAdmin()) {
      history.push('/login')
    }
    return (
      <div className='app' role='presentation'>
        {this.isAdmin() && (
          <AppHeader fixed>
            <Container>
              <DefaultHeader
                history={history}
                isAdminPage={this.state.isAdminPage}
                isAdminFunc={this.isAdminPage}
                isAdmin={this.isAdmin}
              />
            </Container>
          </AppHeader>
        )}
        <div className='app-body'>
          <main className='main'>
            <Container fluid>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component && (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) => (
                        <route.component
                          {...props}
                          isAdminPage={this.isAdminPage}
                          isAdmin={this.isAdmin}
                        />
                      )}
                    />
                  )
                })}
                <Redirect from='/' to='/order' />
              </Switch>
            </Container>
          </main>
        </div>
      </div>
    )
  }
}

export default DefaultLayout
